import React from 'react'
import styled from '@emotion/styled'

import { Link } from 'gatsby'

const NavBar = styled.header`
  background: #0033a0;
  height: ${props => props.calculatedHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 960;
`
const HomeLink = styled(Link)`
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-stretch: normal;
  font-size: 1.2rem;
  line-height: 1.29;
  letter-spacing: -0.4px;
  color: #ffffff;
`

const Header = ({ pageTitle }) => (
  <NavBar calculatedHeight={typeof window !== 'undefined' ? `${window.innerHeight * 0.08}px` : '8vh'}>
    <HomeLink to="/">{pageTitle}</HomeLink>
  </NavBar>
)

export default Header
