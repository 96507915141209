import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'

import Header from './header'

const Page = styled.div`
  height: ${props => props.calculatedHeight};
  margin: 0 auto;
  max-width: 100%;
`

const Layout = ({ children, pageTitle }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Lighthouse helps customers provide their vehicle location.',
            },
            { name: 'keywords', content: 'rescue, roadside' },
          ]}>
          <meta name="viewport" content="width=device-width" />
          <html lang="en" />
        </Helmet>
        <Header pageTitle={pageTitle} />
        <Page calculatedHeight={typeof window !== 'undefined' ? `${window.innerHeight * 0.92}px` : '92vh'}>
          {children}
        </Page>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
